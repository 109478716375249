<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="กรุณาเลือก" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>ข้อตกลงการเติมเงินผู้ใช้</h2>
		<p>สัญญาบริการเติมเงินนี้ (ต่อไปนี้จะเรียกว่า "ข้อตกลงนี้") ทำขึ้นระหว่างท่านกับแพลตฟอร์ม UniLive ภายใต้ PIX
			TECHNOLOGY PTE LTD แพลตฟอร์มจะให้บริการแก่คุณตามกฎการดำเนินงานที่ตกลงกันไว้ในข้อตกลงนี้และเผยแพร่เป็นครั้งคราว
			(ต่อไปนี้จะเรียกว่า "บริการ ขนมหวาน" หรือ "บริการนี้") </p>
		<p>เพื่อให้บริการแก่ผู้ใช้งานได้ดียิ่งขึ้นขอให้ผู้ใช้บริการ (เช่นผู้ใช้หมายเลขบัญชีที่สั่งซื้อ "ขนมหวาน"
			ต่อไปนี้จะเรียกว่า "คุณ") อ่านและเข้าใจข้อตกลงนี้อย่างจริงจังก่อนที่จะเริ่มใช้บริการซึ่งในข้อกำหนดการยกเว้นหรือ
			จำกัด ความรับผิดของแพลตฟอร์มโปรดอ่านอย่างละเอียด </p>
		<p>หากท่านไม่เห็นด้วยกับข้อตกลงนี้ กรุณางดเว้นการดำเนินการขั้นต่อไป (รวมถึงแต่ไม่จำกัดเพียง
			การคลิกที่ปุ่มการดำเนินการ เช่น การเติมเงิน การชำระเงิน) และการใช้บริการในรูปแบบใดๆ </p>
		<p>
			แพลตฟอร์มห้ามผู้เยาว์ใช้บริการ ขนมหวาน
			แพลตฟอร์มขอเตือนด้วยความปรารถนาดีว่าหากคุณเป็นผู้ปกครองของผู้เยาว์และคุณต้องรับผิดชอบในการเป็นผู้ปกครองของผู้เยาว์ที่อยู่ในความดูแลของคุณโปรดเปิดโหมดเยาวชนและ
			/ หรือเครื่องมือป้องกันผู้เยาว์อื่น ๆ
			เมื่อผู้เยาว์ใช้ผลิตภัณฑ์และบริการที่เกี่ยวข้องกับแพลตฟอร์มนี้เพื่อดูแลและแนะนำผู้เยาว์ให้ใช้ผลิตภัณฑ์และบริการที่เกี่ยวข้องอย่างถูกต้องในขณะที่เสริมสร้างข้อ
			จำกัด
			และการจัดการวิธีการชำระเงินทางอินเทอร์เน็ตและร่วมกันสร้างสภาพแวดล้อมที่ดีสำหรับการเติบโตที่ดีต่อสุขภาพของผู้เยาว์
		</p>
		<h3> I. เนื้อหาบริการ</h3>
		<p>
			1、 "ขนมหวาน" เป็นเครื่องมือเสมือนที่แพลตฟอร์มมีให้คุณและ จำกัด เฉพาะการบริโภคที่เกี่ยวข้องภายในแพลตฟอร์ม
			เมื่อคุณซื้อ "ขนมหวาน" แล้ว คุณสามารถใช้
			รับสินค้าหรือบริการของแพลตฟอร์มตามคําแนะนําและคําแนะนําของหน้าที่เกี่ยวข้องกับแพลตฟอร์ม
			รวมถึงแต่ไม่จํากัดเฉพาะบริการให้รางวัลสด (ผู้ใช้สามารถจ่ายเงินสําหรับการพิจารณาสําหรับบริการต่างๆ เช่น
			การแสดงสตรีมมิ่งโดยการให้ของขวัญเสมือนจริงแก่ผู้ประกาศข่าว) เป็นต้น เพื่อหลีกเลี่ยงข้อสงสัย บริการ ขนมหวาน
			เป็นเทคโนโลยีเครือข่ายโดยรวมและบริการที่เกี่ยวข้องที่ผู้ใช้จ่ายเงินและแพลตฟอร์มให้บริการแก่ผู้ใช้อย่างต่อเนื่อง
			บริการ ขนมหวาน ไม่ใช่บริการชําระเงินทางอินเทอร์เน็ตและ "ขนมหวาน"
			ไม่ใช่ตั๋วโทเค็นสกุลเงินเสมือนหรือบัตรกํานัลการชําระเงินล่วงหน้าซึ่งไม่มีมูลค่าสกุลเงินมูลค่าล่วงหน้า
		</p>
		<p>2、 หลังจากที่คุณซื้อ "ขนมหวาน" แล้ว คุณจะสามารถใช้ "ขนมหวาน"
			เพื่อวัตถุประสงค์ที่ตกลงกันไว้ภายใต้ข้อตกลงนี้เท่านั้น
			และไม่สามารถนำไปใช้นอกเหนือขอบเขตของผลิตภัณฑ์/บริการที่บริษัทจัดหาให้ และไม่สามารถโอนระหว่างบัญชี UniLive อื่นได้
		</p>
		<p>
			3 คุณควรซื้อ "ขนมหวาน" ผ่านช่องทางที่เป็นทางการที่กำหนดโดยแพลตฟอร์มและแพลตฟอร์มไม่รับรองช่องทางใด ๆ
			ที่ไม่ได้รับอนุญาตจาก บริษัท หากคุณใช้ช่องทางที่ไม่เป็นทางการในการซื้อ "ขนมหวาน"
			แพลตฟอร์มไม่สามารถรับประกันได้ว่า "ขนมหวาน"
			ดังกล่าวจะเข้าสู่บัญชีของคุณได้อย่างราบรื่นและการกระทําดังกล่าวอาจมาพร้อมกับความเสี่ยงเช่นการฉ้อโกงการฟอกเงินและก่อให้เกิดความสูญเสียหรือความเสียหายที่แก้ไขไม่ได้ต่อคุณแพลตฟอร์มและบุคคลที่สามที่เกี่ยวข้อง
			ดังนั้นการซื้อผ่านช่องทางที่ไม่เป็นทางการจะถือว่าเป็นการละเมิดเมื่อแพลตฟอร์มมีสิทธิ์ที่จะหักหรือล้าง "ขนมหวาน"
			ในบัญชีของคุณ จำกัด
			การทำงานทั้งหมดหรือบางส่วนของบัญชีของคุณจนกว่าบัญชีของคุณจะถูกแบนอย่างถาวรและคุณต้องรับผิดชอบต่อความสูญเสียที่เกิดขึ้นเอง
			คุณจะต้องรับผิดชอบชดใช้ค่าเสียหายในกรณีที่แพลตฟอร์มหรือบุคคลที่สามอื่น ๆ
			ได้รับความเสียหายจากการละเมิดข้อตกลงดังกล่าวของคุณ หากคุณซื้อผ่านช่องทางต่างๆ เช่น
			ร้านค้าอย่างเป็นทางการที่เปิดโดยแพลตฟอร์มบนแพลตฟอร์มของบุคคลที่สาม เช่น Tmall
			แพลตฟอร์มจะระบุตัวตนของผู้ใช้บริการผ่านทางข้อมูลผู้ใช้ที่ออกหมายเลขบัญชีที่คุณระบุใน "ขนมหวาน"
			และ/หรือข้อตกลงอื่นระหว่างคุณกับบริษัท (ถ้ามี)
		</p>
		<p>
			4, การซื้อ "ขนมหวาน" จะถูกเรียกเก็บโดย บริษัท หรือคู่ค้าที่ได้รับการแต่งตั้งจาก บริษัท
			แพลตฟอร์มจะเตือนคุณโดยเฉพาะอย่างยิ่งว่าผู้ให้บริการที่เกี่ยวข้องในช่องทางการซื้อที่แตกต่างกันอาจเรียกเก็บค่าบริการช่องทางเมื่อคุณชำระเงินตามกลยุทธ์การดำเนินงานของตนเองซึ่งอาจส่งผลให้เกิดความแตกต่างในจำนวนค่าธรรมเนียมที่คุณซื้อ
			"ขนมหวาน" จำนวนเดียวกันผ่านช่องทางต่าง ๆ หรือจำนวน "ขนมหวาน" ที่ซื้อโดยชำระค่าธรรมเนียมเท่ากัน
			ทั้งนี้ขึ้นอยู่กับการแสดงของหน้าเมื่อคุณซื้อ "ขนมหวาน"
			โปรดทราบเพื่อยืนยันข้อมูลของหน้าเว็บที่เกี่ยวข้องและเลือกช่องทางการซื้อ "ขนมหวาน" อย่างสมเหตุสมผล
		</p>
		<p>5 หากคุณตั้งใจจะซื้อ "ขนมหวาน" สำหรับบัญชีของผู้อื่น แพลตฟอร์มจะออก "ขนมหวาน"
			ในปริมาณที่สอดคล้องกันภายใต้หมายเลขบัญชีที่คุณระบุซึ่งผู้ใช้สามารถใช้บริการ "ขนมหวาน"
			ที่แพลตฟอร์มมีให้ตามข้อตกลงนี้
			ในกรณีที่มีข้อพิพาทเกิดขึ้นโดยคุณและผู้ใช้บัญชีนั้นจะได้รับการแก้ไขโดยการเจรจาของคุณเองและแพลตฟอร์มไม่จําเป็นต้องรับผิดชอบต่อคุณและผู้ใช้บัญชีนั้นในเรื่องนี้
		</p>
		<h3> ครั้งที่สอง การบริโภคอย่างมีเหตุผล</h3>
		<p> 1 แพลตฟอร์มสนับสนุนการบริโภคที่มีเหตุผลการบริโภคและการใช้จ่าย อย่าลืมซื้อและใช้บริการ ขนมหวาน
			อย่างสมเหตุสมผลตามกำลังการใช้จ่ายและความต้องการที่แท้จริงของคุณและหลีกเลี่ยงการบริโภคที่มากเกินไป</p>
		<p>
			2、 เงินที่คุณใช้ในการซื้อ "ขนมหวาน" จะเป็นรายได้ที่คุณได้รับอย่างถูกต้องตามกฎหมายและมีสิทธิที่จะใช้
			หากคุณละเมิดข้อตกลงนี้ ข้อพิพาทหรือข้อพิพาทใด ๆ ที่เกิดขึ้นคุณจะต้องได้รับการแก้ไขและยอมรับผลทางกฎหมายด้วยตนเอง
			หากการกระทำของคุณก่อให้เกิดความเสียหายต่อแพลตฟอร์มหรือบุคคลที่สาม คุณจะต้องชดใช้ค่าเสียหายเต็มจำนวน
			แพลตฟอร์มมีสิทธิ์ที่จะหักหรือล้าง "ขนมหวาน" ในบัญชีของคุณ จำกัด
			การทำงานทั้งหมดหรือบางส่วนของบัญชีของคุณจนกว่าจะปิดบัญชีของคุณตลอดไปหากแพลตฟอร์มพบว่า (รวมถึงแต่ไม่ จำกัด
			เฉพาะกรณีที่มีการค้นพบเชิงรุกได้รับการร้องเรียนจากบุคคลที่สามหรือการแจ้งเตือนจากหน่วยงานที่มีอำนาจศาลศาลและอื่น ๆ
			) ว่าคุณสงสัยว่ามีการละเมิดข้อตกลงดังกล่าว
			ขณะที่แพลตฟอร์มมีสิทธิเก็บข้อมูลที่เกี่ยวข้องและรายงานต่อหน่วยงานที่มีอำนาจหน้าที่ที่เกี่ยวข้องหน่วยงานยุติธรรม
		</p>
		<p>
			3.
			แพลตฟอร์มต่อต้านการชักจูงการกระตุ้นการปลุกระดมผู้ใช้ให้รางวัลอย่างไม่มีเหตุผลและการล่อลวงสนับสนุนผู้เยาว์ให้รางวัลด้วยข้อมูลระบุตัวตนที่เป็นเท็จอย่างเคร่งครัด
			หากคุณพบการละเมิดดังกล่าวคุณสามารถรายงานไปยังแพลตฟอร์มได้ตามช่องทางการประชาสัมพันธ์ของแพลตฟอร์ม
			(ด้านล่างขวาของห้องถ่ายทอดสด - การแจ้งเบาะแส, แพลตฟอร์มการกำกับดูแลตนเองของอุตสาหกรรมการถ่ายทอดสด UniLive -
			การแจ้งเบาะแส ฯลฯ ) และแพลตฟอร์มจะใช้มาตรการกำจัดตามกฎหมายและระเบียบข้อบังคับ
			หวังว่าจะได้ร่วมกันสร้างระบบนิเวศของชุมชนที่มีสุขภาพดีและเป็นระเบียบ
		</p>
		<h3>สาม ภาระผูกพันตามสิทธิของคุณ</h3>
		<p>1 ข้อมูลส่วนบุคคลหรือข้อมูลที่ท่านให้ไว้ในระหว่างการใช้บริการต้องเป็นความจริง ถูกต้อง
			และจะได้รับความช่วยเหลือและให้ความร่วมมือกับการสืบสวนของหน่วยงานกำกับดูแล (ถ้าจำเป็น) ตามที่กฎหมายกำหนด
			และตามที่แพลตฟอร์มร้องขอ </p>
		<p>
			2 คุณควรเลือกอย่างระมัดระวังในการซื้อ "ขนมหวาน" และ / หรือป้อนข้อมูลบัญชี /
			หมายเลขบัญชีผูกกล่องจดหมายปริมาณการซื้อ "ขนมหวาน" ฯลฯ ในกรณีที่เกิดความผิดพลาดในการซื้อ เช่น
			หมายเลขบัญชีไม่ถูกต้อง จำนวน "ขนมหวาน" ที่ผิดพลาดอันเนื่องมาจากปัจจัยต่างๆ เช่น การป้อนข้อมูลผิดพลาด
			การดำเนินการที่ไม่เหมาะสม ความไม่เข้าใจอย่างถ่องแท้เกี่ยวกับวิธีการเรียกเก็บเงิน ฯลฯ
			แพลตฟอร์มมีสิทธิ์ที่จะไม่ชดเชยหรือชดเชย
		</p>
		<p>3 ท่านจะต้องเก็บรักษา ใช้หมายเลขบัญชีของท่านอย่างถูกต้อง
			และรับผิดชอบต่อการกระทำและผลที่ตามมาทั้งหมดภายใต้หมายเลขบัญชีนั้น
			ในกรณีที่มีข้อผิดพลาดเมื่อแพลตฟอร์มไม่สามารถให้บริการหรือให้บริการอันเนื่องมาจากสถานการณ์ต่อไปนี้ของคุณซึ่งเป็นผลมาจากการสูญเสียของคุณและแพลตฟอร์มจะไม่รับผิดชอบทางกฎหมายยกเว้นตามที่กฎหมายและข้อบังคับกำหนดไว้อย่างชัดเจน
		</p>
		<p>(1) บัญชีของคุณเป็นโมฆะ, สูญหาย, ถูกยักยอก, ถูกแบน; </p>
		<p>(2) สถาบันการชำระเงินบุคคลที่สาม บัญชีธนาคารที่ผูกกับหมายเลขบัญชีของคุณถูกระงับ ถูกยึด หรือเกิดความผิดปกติอื่น ๆ
			หรือคุณใช้บัญชีที่ไม่ได้รับการรับรองหรือบัญชีที่ไม่ใช่ของคุณเอง </p>
		<p>(3) ท่านแจ้งรหัสผ่านของบัญชีแก่ผู้อื่นหรือได้อนุญาตให้ผู้อื่นเข้าสู่ระบบเพื่อใช้บัญชีของท่าน </p>
		<p>(4) กรณีอื่นๆ ที่คุณมีเจตนาหรือประมาทเลินเล่ออย่างร้ายแรง </p>
		<p>
			4、 ท่านจะใช้บริการโดยชอบด้วยกฎหมายและตามกฎระเบียบ และจะไม่ใช้บริการเพื่อวัตถุประสงค์ใดๆ ที่เป็นการกระทำผิดกฎหมาย
			ขัดต่อความสงบเรียบร้อย ศีลธรรมอันดีของสังคม หรือขัดขวางการดำเนินงานตามปกติของแพลตฟอร์ม
			ละเมิดสิทธิและผลประโยชน์ที่ชอบด้วยกฎหมายของบุคคลที่สาม และท่านจะต้องไม่ละเมิดเอกสารหรือข้อกำหนดอื่นๆ
			ที่มีผลผูกพันกับท่าน (ถ้ามี)
			แพลตฟอร์มจะเตือนคุณโดยเฉพาะอย่างยิ่งว่าอย่ายืมโอนหรือให้บัญชีของคุณแก่ผู้อื่นเพื่อใช้เพื่อป้องกันไม่ให้ผู้อื่นละเมิดข้อตกลงดังกล่าวผ่านบัญชีของคุณเพื่อปกป้องบัญชีและทรัพย์สินของตนเองให้ปลอดภัย
		</p>
		<p>
			5.
			แพลตฟอร์มให้บริการคืนเงินสำหรับการใช้จ่ายของผู้เยาว์ตามกฎหมายและระเบียบข้อบังคับเพื่อปกป้องสิทธิและผลประโยชน์ที่ชอบด้วยกฎหมายของผู้เยาว์และผู้ปกครอง
			ท่านไม่ควรใช้บริการเพื่อวัตถุประสงค์ที่ผิดกฎหมายหรือในลักษณะที่ไม่เหมาะสม รวมถึงแต่ไม่จำกัดเพียง
			การที่ผู้ใหญ่ปลอมตัวเป็นผู้เยาว์เพื่อฉ้อโกงเงินคืน การขอเงินคืนหลังจากการชักจูงให้ผู้เยาว์ใช้จ่าย เป็นต้น
			การกระทำดังกล่าวจะถือเป็นการละเมิดข้อตกลงอย่างร้ายแรงและแพลตฟอร์มมีสิทธิ์ที่จะปฏิเสธการคืนเงินเมื่อได้รับการยืนยันอย่างสมเหตุสมผลและสงวนสิทธิ์ในการติดตามความรับผิดชอบทางกฎหมายของคุณต่อไป
		</p>
		<p>
			6、 คุณต้องสัญญาว่าจะไม่ใช้บัญชีพิเศษสำหรับกิจกรรมที่ผิดกฎหมายเช่นการฟอกเงินและยอมรับคำขอที่ผิดกฎหมายใด ๆ
			ที่ผู้อื่นใช้คุณผ่านบัญชีพิเศษสำหรับการฟอกเงิน ฯลฯ หากคุณละเมิดข้อตกลงดังกล่าวแพลตฟอร์มมีสิทธิ์ที่จะใช้มาตรการต่าง
			ๆ เช่นการระงับการให้บริการเริ่มการสอบสวนภายใต้บทบัญญัติของกฎหมายที่เกี่ยวข้องกับการป้องกันและปราบปรามการฟอกเงินและ
			/ หรือการร้องขอจากหน่วยงานที่มีอำนาจศาลและไม่ต้องรับผิดชอบต่อความสูญเสียหรือความเสียหายใด ๆ ที่คุณได้รับ
		</p>
		<p>
			7、 ในกรณีที่คุณใช้บริการเกี่ยวข้องกับบริการที่เกี่ยวข้องที่จัดหาโดยบุคคลที่สาม (เช่นบริการการชำระเงิน ฯลฯ )
			นอกเหนือจากการปฏิบัติตามข้อตกลงในข้อตกลงนี้คุณจะต้องยินยอมและปฏิบัติตามข้อตกลงและกฎที่เกี่ยวข้องของบุคคลที่สามดังกล่าวและในกรณีใด
			ๆ
			ข้อพิพาทที่เกิดขึ้นจากบุคคลที่สามและบริการที่เกี่ยวข้องที่จัดหาจะถูกแก้ไขโดยคุณเองและบุคคลที่สามที่แพลตฟอร์มไม่จำเป็นต้องรับผิดชอบต่อคุณหรือบุคคลที่สามดังกล่าว
		</p>
		<h3>IV. หน้าที่สิทธิของแพลตฟอร์ม </h3>
		<p>
			1. แพลตฟอร์มมีสิทธิ์ที่จะกำหนดข้อ จำกัด ที่เกี่ยวข้องการแจ้งเตือนและอื่น ๆ เกี่ยวกับบริการ "ขนมหวาน"
			เป็นครั้งคราวโดยพิจารณาจากการแก้ไขกฎหมายและข้อบังคับข้อกำหนดของหน่วยงานกำกับดูแลการรักษาความปลอดภัยการซื้อขายการปรับปรุงกลยุทธ์การดำเนินงานการเปลี่ยนแปลงของสภาพแวดล้อมของตลาด
			ฯลฯ รวมถึง แต่ไม่ จำกัด เพียงการ จำกัด วงเงินการทำธุรกรรมและ /
			หรือจำนวนธุรกรรมของผู้ใช้ทั้งหมดหรือบางส่วนการห้ามผู้ใช้เฉพาะใช้บริการหรือการเพิ่มการตรวจสอบธุรกรรม ฯลฯ
		</p>
		<p>
			2. เพื่อความปลอดภัยของการทำธุรกรรม แพลตฟอร์มยังมีสิทธิ์ในการตรวจสอบพฤติกรรมการใช้บริการของคุณ
			สำหรับผู้ใช้หรือหมายเลขบัญชีที่ได้รับการยอมรับอย่างสมเหตุสมผลว่ามีความเสี่ยงสูง
			แพลตฟอร์มอาจใช้มาตรการที่จำเป็นเพื่อป้องกันการขยายตัวของความเสี่ยง
			ปกป้องทรัพย์สินของผู้ใช้และความปลอดภัยทางนิเวศวิทยาของแพลตฟอร์ม มาตรการที่จําเป็นดังกล่าวรวมถึงการหักหรือล้าง
			"ขนมหวาน" ในบัญชีของคุณ จํากัดการทํางานทั้งหมดหรือบางส่วนของบัญชีของคุณ การปิดกั้นบัญชีของคุณในระยะสั้นหรือถาวร
			ฯลฯ
		</p>
		<p>
			3
			แพลตฟอร์มมีสิทธิ์ที่จะแก้ไขข้อผิดพลาดนั้นเมื่อแพลตฟอร์มพบข้อผิดพลาดในการประมวลผลที่เกิดจากความล้มเหลวของระบบหรือสาเหตุอื่น
			ๆ ไม่ว่าจะเป็นประโยชน์ต่อแพลตฟอร์มหรือเป็นประโยชน์ต่อคุณ ณ เวลานี้หากคุณได้รับ "ขนมหวาน" น้อยกว่าที่คุณควรได้รับ
			"ขนมหวาน" แพลตฟอร์มจะเติมส่วนต่างไปยังบัญชีของคุณโดยเร็วที่สุดหลังจากยืนยันข้อผิดพลาดในการประมวลผล หากคุณได้รับ
			"ขนมหวาน" มากกว่าที่คุณควรได้รับ "ขนมหวาน"
			แพลตฟอร์มมีสิทธิ์หักส่วนต่างโดยตรงจากบัญชีของคุณโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
		</p>
		<p>4 แพลตฟอร์มมีสิทธิ์ที่จะเปลี่ยนแปลง หยุดชะงัก ระงับ หรือยกเลิกบริการนี้ ขึ้นอยู่กับสถานการณ์เฉพาะ เช่น
			ความปลอดภัยของธุรกรรม การวางแผนการดำเนินงาน กฎหมายและข้อบังคับของประเทศ หรือข้อกำหนดของหน่วยงานกำกับดูแล
			และแพลตฟอร์มจะไม่รับผิดชอบต่อการคุ้มครองสิทธิหรือการละเมิดใด ๆ ต่อคุณ </p>
		<h3>V. เงื่อนไขการปฏิเสธความรับผิดชอบ</h3>
		<p>
			1
			คุณเข้าใจและยอมรับว่าบริการนี้มีให้บริการตามสภาพที่เป็นอยู่ซึ่งสามารถเข้าถึงได้โดยเทคโนโลยีและเงื่อนไขที่มีอยู่และแพลตฟอร์มจะพยายามอย่างดีที่สุดในการให้บริการแก่คุณและรับประกันความต่อเนื่องและความปลอดภัยของบริการ
			แต่คุณรับทราบและยอมรับว่าแพลตฟอร์มไม่สามารถคาดการณ์และป้องกันความเสี่ยงด้านเทคนิคและความเสี่ยงอื่น ๆ
			ได้ตลอดเวลาหรือเสมอรวมถึงแต่ไม่ จำกัด
			เพียงการหยุดชะงักหรือความผิดปกติของการบริการที่อาจเกิดจากเหตุสุดวิสัยสาเหตุเครือข่ายข้อบกพร่องในการให้บริการของบุคคลที่สามและอื่น
			ๆ หากเกิดเหตุการณ์ดังกล่าวแพลตฟอร์มมีความพยายามทางธุรกิจมากที่สุดในการปรับปรุง
			แต่ไม่จำเป็นต้องรับผิดชอบทางกฎหมายต่อคุณหรือบุคคลที่สามอื่น ๆ ในเรื่องนี้
		</p>
		<p>2 แพลตฟอร์มอาจดำเนินการบำรุงรักษาการหยุดทำงาน การอัพเกรดระบบ และการปรับแต่งระบบด้วยตนเอง
			เนื่องจากเป็นเหตุให้คุณไม่สามารถใช้บริการได้ตามปกติ คุณยอมรับว่าแพลตฟอร์มไม่จำเป็นต้องรับผิดชอบทางกฎหมาย </p>
		<p>3 ไม่ว่าในกรณีใด ๆ แพลตฟอร์มจะไม่รับผิดชอบต่อความเสียหายทางอ้อมการลงโทษอุบัติเหตุหรือการลงโทษ
			และแพลตฟอร์มมีความรับผิดชอบต่อคุณอย่างเต็มที่ไม่ว่าจะด้วยเหตุผลใดหรือด้วยวิธีใดก็ตามจะไม่เกินค่าใช้จ่ายที่คุณจ่ายเนื่องจากการใช้งาน
		</p>
		<h3>หก. ความรับผิดสำหรับการละเมิด</h3>
		<p>1 ในกรณีที่คุณละเมิดข้อตกลงนี้
			แพลตฟอร์มมีสิทธิ์ที่จะใช้มาตรการการจัดการกับคุณหรือบัญชีของคุณตามกฎหมายและระเบียบข้อบังคับ ข้อตกลงที่เกี่ยวข้อง
			และข้อบังคับการจัดการแพลตฟอร์ม ขึ้นอยู่กับสถานการณ์เฉพาะและระดับของอันตรายของการกระทำของคุณ รวมถึงแต่ไม่จำกัดเพียง
			การเตือน การหัก หรือการล้าง "ขนมหวาน" ภายในบัญชีของคุณ การจำกัดการทำงานของบัญชีของคุณทั้งหมดหรือบางส่วน
			การปิดกั้นบัญชีของคุณในระยะสั้นหรือถาวร เป็นต้น
		</p>
		<p>
			2.
			คุณเข้าใจและรับทราบว่าหากมีบุคคลที่สามที่สงสัยว่าใช้บัญชีของคุณเพื่อกระทำการฝ่าฝืนกฎหมายหรือละเมิดข้อตกลงในข้อตกลงนี้
			เพื่อปกป้องสิทธิและผลประโยชน์ที่ชอบด้วยกฎหมายของเหยื่อและผู้ใช้อื่น ๆ
			รักษาระเบียบนิเวศวิทยาของแพลตฟอร์มและป้องกันความเสียหายจากการขยายตัวต่อไป
			แพลตฟอร์มยังมีสิทธิ์ที่จะใช้มาตรการการจัดการกับบัญชีของคุณตามข้อตกลงนี้และกฎที่เกี่ยวข้องของแพลตฟอร์มซึ่งคุณไม่ควรขอให้แพลตฟอร์มรับผิดชอบทางกฎหมาย
		</p>
		<p>
			3
			ในกรณีที่คุณได้กระทำหรือสงสัยว่าได้กระทำผิดกฎหมายหรือประพฤติมิชอบนอกแพลตฟอร์มเพื่อให้แพลตฟอร์มมีเหตุผลอันสมควรที่จะเชื่อว่าคุณได้กระทำหรือกำลังจะละเมิดข้อตกลงนี้หรือกฎอื่น
			ๆ ของแพลตฟอร์มที่คุณสามารถใช้กับคุณ (เช่นข้อตกลงการบริการผู้ใช้ UniLive, อนุสัญญาว่าด้วยการกำกับดูแลตนเองของชุมชน
			ฯลฯ ) แพลตฟอร์มยังมีสิทธิ์ที่จะใช้มาตรการการจัดการกับคุณตามที่ตกลงไว้ในวรรค 1
			ของข้อนี้และคุณไม่ควรขอให้แพลตฟอร์มรับผิดชอบทางกฎหมาย
		</p>



	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/RechargeAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/RechargeAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/RechargeAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/RechargeAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/RechargeAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/RechargeAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/RechargeAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/RechargeAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/RechargeAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/RechargeAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/RechargeAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/RechargeAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/RechargeAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/RechargeAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>